/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <vx-card class="mb-4">
        <vue-draggable-container id="wordBank" class="wordBank">
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Implement URL Filtering</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Implement row level access control</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Drop Test DB</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Implement a WAF</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Verify OAuth Integrations</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Conduct CI/CD Pipeline Review</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Limit the size of user mailboxes</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Implement Input Validation</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Implement RBAC</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Conduct Code Review</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">POD Scanning</h5>
          <h5 class="dragBlock font-semibold cursor-pointer z-50">Implement anti-spam software</h5>
        </vue-draggable-container>
      </vx-card>
      <vx-card class="mb-4">
        <div class="grid simulation-grid mb-2">
          <h3 class="font-bold">Toronto, ON | Computer Science Lab</h3>
          <div />
          <h3 class="font-bold">Miami, FL | Marketing & Business Hall</h3>
        </div>
        <div class="grid simulation-grid">
          <div class="main-border bg-light rounded-lg p-4 relative" style="z-index: 10">
            <div class="grid grid-cols-3 gap-4 mb-6">
              <div class="rounded-lg flex flex-col justify-between items-center pt-2 z-10" style="background: #181d2a">
                <div class="flex flex-col">
                  <div style="min-height: 110px" class="flex items-center justify-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/gitlab-icon.png"
                      alt="GitLab"
                      width="80"
                    />
                  </div>
                  <h3 class="text-center">Code Repo VC</h3>
                </div>

                <div class="p-2 w-full flex flex-col">
                  <vue-draggable-container id="input0" class="answerStandard dropzone dropzoneStandard answer-bg w-full mb-2" />
                  <vue-draggable-container id="input1" class="answerStandard dropzone dropzoneStandard answer-bg w-full" />
                </div>
              </div>
              <div class="rounded-lg flex flex-col items-center justify-between pt-2 z-10" style="background: #181d2a">
                <div class="flex flex-col">
                  <div style="min-height: 110px" class="flex items-center justify-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/kubernetes-logo.png"
                      alt="Kubernetes"
                      width="110"
                    />
                  </div>
                  <h3 class="text-center">Container Server</h3>
                </div>

                <div class="p-2 w-full flex flex-col">
                  <vue-draggable-container id="input2" class="answerStandard dropzone dropzoneStandard answer-bg w-full mb-2" />
                  <vue-draggable-container id="input3" class="answerStandard dropzone dropzoneStandard answer-bg w-full" />
                </div>
              </div>
              <div class="rounded-lg flex flex-col items-center justify-between pt-2 z-10" style="background: #181d2a">
                <div class="flex flex-col">
                  <div style="min-height: 110px" class="flex items-center justify-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/apache-logo.png"
                      alt="Apache HTTP Server Project"
                      width="110"
                    />
                  </div>
                  <h3 class="text-center">HTTP Server</h3>
                </div>

                <div class="p-2 w-full flex flex-col">
                  <vue-draggable-container id="input4" class="answerStandard dropzone dropzoneStandard answer-bg w-full mb-2" />
                  <vue-draggable-container id="input5" class="answerStandard dropzone dropzoneStandard answer-bg w-full" />
                </div>
              </div>
            </div>
            <div class="grid grid-cols-3 gap-4 mb-6">
              <div class="flex items-center justify-center">
                <div class="app-connecting-line-up" />
              </div>
              <div class="flex items-center justify-center">
                <div class="app-connecting-line-up" />
                <div class="app-connecting-line-across" />
              </div>
              <div class="flex items-center justify-center">
                <div class="app-connecting-line-up" />
              </div>
            </div>
            <div class="grid grid-cols-3 gap-4 mb-12">
              <div />
              <div class="rounded-lg flex flex-col items-center justify-center z-10" style="background: #c93738">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/switch.png"
                  alt="Kubernetes"
                  width="110"
                />
                <h3 class="mb-4 font-bold text-center">Network Switch</h3>
              </div>
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div />
              <div class="flex items-center justify-center">
                <div class="network-switch-line-up" />
              </div>
              <div />
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div />
              <div class="rounded-lg flex flex-col items-center justify-center mb-5 z-10" style="background: #31b952">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/router.png"
                  alt="Kubernetes"
                  width="110"
                  class="pt-3 pb-3"
                />
                <h3 class="mb-4 font-bold text-center">Site - X<br />Router</h3>
              </div>
              <div />
            </div>
          </div>
          <div class="flex flex-col justify-between items-center">
            <div
              class="rounded-lg flex flex-col items-center justify-center mb-5 z-10 w-3/4 cursor-pointer"
              style="background: #040915; border-style: ridge; border-color: #c93738"
              @click="showHackersPC = !showHackersPC"
            >
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/laptop.png"
                alt="Hacker PC"
                width="130"
                class="pt-3 pb-3"
              />
              <h3 class="mb-4 font-bold text-center">Hacker PC</h3>
            </div>
            <div class="rounded-lg flex flex-col items-center justify-center mb-5 z-10 w-3/4 main-border" style="background: #040915">
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/fedora-hat.png"
                alt="Netherlands Proxy Server"
                width="110"
                class="pt-3 pb-3"
              />
              <h3 class="mb-4 font-bold text-center">Netherlands<br />Proxy Server</h3>
            </div>
            <div class="hacker-pc-line-up" style="margin-top: 50px" />
            <div class="flex items-center justify-center w-full">
              <div class="ip-sec-vpn-tunnel" />
              <div class="rounded-lg flex flex-col items-center justify-center mb-5 z-10 w-3/4 main-border" style="background: #040915">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/vpn-tunnel.png"
                  alt="IPSec STS VPN Tunnel"
                  width="110"
                  class="pt-3 pb-3"
                />
                <h3 class="mb-4 font-bold text-center">IPSec STS<br />VPN Tunnel</h3>
              </div>
            </div>
          </div>
          <div class="main-border bg-light rounded-lg p-4 relative" style="z-index: 10">
            <div class="grid grid-cols-3 gap-4 mb-6">
              <div class="rounded-lg flex flex-col items-center justify-center pt-2 pb-2 z-10" style="background: #181d2a">
                <div style="min-height: 110px" class="flex items-center justify-center">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/mysql-logo.png"
                    alt="MySQL"
                    width="110"
                  />
                </div>
                <h3 class="text-center">Database Server</h3>
                <div class="p-2 w-full">
                  <vue-draggable-container id="input6" class="answerStandard dropzone dropzoneStandard answer-bg w-full mb-2" />
                  <vue-draggable-container id="input7" class="answerStandard dropzone dropzoneStandard answer-bg w-full" />
                </div>
              </div>
              <div class="rounded-lg flex flex-col items-center justify-center pt-2 pb-2 z-10" style="background: #181d2a">
                <div style="min-height: 110px" class="flex items-center justify-center">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/salesforce-logo.png"
                    alt="salesForce"
                    width="110"
                  />
                </div>
                <h3 class="text-center">CRM Application</h3>
                <div class="p-2 w-full">
                  <vue-draggable-container id="input8" class="answerStandard dropzone dropzoneStandard answer-bg w-full mb-2" />
                  <vue-draggable-container id="input9" class="answerStandard dropzone dropzoneStandard answer-bg w-full" />
                </div>
              </div>
              <div class="rounded-lg flex flex-col items-center justify-center pt-2 pb-2 z-10" style="background: #181d2a">
                <div style="min-height: 110px" class="flex items-center justify-center">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/sendy-email-logo.png"
                    alt="Sendy Marketing"
                    width="100"
                  />
                </div>
                <h3 class="text-center">Email Server</h3>
                <div class="p-2 w-full">
                  <vue-draggable-container id="input10" class="answerStandard dropzone dropzoneStandard answer-bg w-full mb-2" />
                  <vue-draggable-container id="input11" class="answerStandard dropzone dropzoneStandard answer-bg w-full" />
                </div>
              </div>
            </div>
            <div class="grid grid-cols-3 gap-4 mb-6">
              <div class="flex items-center justify-center">
                <div class="app-connecting-line-up" />
              </div>
              <div class="flex items-center justify-center">
                <div class="app-connecting-line-up" />
                <div class="app-connecting-line-across" />
              </div>
              <div class="flex items-center justify-center">
                <div class="app-connecting-line-up" />
              </div>
            </div>
            <div class="grid grid-cols-3 gap-4 mb-12">
              <div />
              <div class="rounded-lg flex flex-col items-center justify-center z-10" style="background: #c93738">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/switch.png"
                  alt="Kubernetes"
                  width="110"
                />
                <h3 class="mb-4 font-bold text-center">Network Switch</h3>
              </div>
              <div />
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div />
              <div class="flex items-center justify-center">
                <div class="network-switch-line-up" />
              </div>
              <div />
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div />
              <div class="rounded-lg flex flex-col items-center justify-center mb-5 z-10" style="background: #31b952">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/router.png"
                  alt="Kubernetes"
                  width="110"
                  class="pt-3 pb-3"
                />
                <h3 class="mb-4 font-bold text-center">Site - W<br />Router</h3>
              </div>
              <div />
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-4">
        <h3 class="font-bold mb-3">What Attack Is Being Conducted</h3>
        <v-select placeholder="Select Attack Type" class="w-1/5 mb-2" :options="attackTypeOptions" v-model="selectedAttackType" />
        <small v-if="correctAttackType && correctAttackType === false" class="ml-1 text-danger font-semibold"
          >This answer is incorrect</small
        >
        <small v-if="correctAttackType && correctAttackType === true" class="ml-1 text-success font-semibold">This answer is correct</small>
        <small v-if="!selectedAttackType" class="ml-1 text-danger font-semibold">Please select an attack type before proceeding</small>
      </vx-card>
    </vue-sortable>

    <!--- HACKERS PC --->
    <slide-y-up-transition>
      <div v-if="showHackersPC" style="position: absolute; top: 34%; left: 21.5vw; z-index: 999999">
        <div class="marvel-device macbook">
          <div class="top-bar"></div>
          <div class="camera"></div>
          <div class="screen flex items-center justify-center align-middle">
            <div class="p-8 pt-2 pb-4 rounded w-4/5" v-if="hackerPCSuccess === false" style="background: rgba(0, 0, 0, 0.45)">
              <h1 class="font-bold my-3">Staff Login</h1>
              <h3 class="my-2 border border-solid border-dark p-2 rounded">
                {{ activeUserInfo.firstName.toLowerCase().substr(0, 11) }}@utoronto.ca
              </h3>
              <h3 class="my-2 border border-solid border-dark p-2 rounded">••••••••••••••</h3>
              <vs-button color="dark" type="border" class="my-2 w-full" @click="hackerPCSuccess = true">Login</vs-button>
            </div>
            <div class="p-8 pt-2 pb-4 rounded w-4/5" v-else style="background: rgba(0, 0, 0, 0.45)">
              <h1 class="font-bold my-3">UT Secure Portal</h1>
              <h4 class="my-2 font-semibold break-all">
                userId,accessCookie,tokenExp<br />
                516,eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9,1448968145<br />
                640,eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6,1449022145<br />
                718,IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIy,1417486145<br />
                1003,eyJzdWIiOiIxMjM0NjoxNTE2MjM5MDIyfQa,1577153345<br />
              </h4>
            </div>
            <div class="flex text-white ml-2 cursor-pointer" @click="showHackersPC = false">
              <feather-icon icon="XCircleIcon" svgClasses="w-8 h-8" />
            </div>
          </div>
          <div class="bottom-bar"></div>
        </div>
      </div>
    </slide-y-up-transition>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      showHackersPC: false,
      hackerPCSuccess: false,

      selectedAttackType: null,
      correctAttackType: null,

      attackTypeOptions: [
        { id: 1, label: 'SQL Injection' },
        { id: 2, label: 'Cross Site Scripting' },
        { id: 3, label: 'XML Injection' },
        { id: 4, label: 'Session Hijacking' },
        { id: 5, label: 'Buffer Overflow' },
        { id: 7, label: 'Windows Update Required' },
        { id: 7, label: 'Brute Force Attack' },
        { id: 8, label: 'Remote Access Trojan' },
      ],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      //INPUT 0 AND 1
      const input0 = document.getElementById('input0');
      const input1 = document.getElementById('input1');

      if (input0.textContent === 'Conduct CI/CD Pipeline Review' || input0.textContent === 'Conduct Code Review') {
        input0.style.borderColor = '#28C76F';
        input0.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input1.textContent !== 'Conduct CI/CD Pipeline Review' || input1.textContent !== 'Conduct Code Review') {
        input0.style.borderColor = '#fd4445';
        input0.style.backgroundColor = '#fd4445';
      }

      if (input1.textContent === 'Conduct CI/CD Pipeline Review' || input1.textContent === 'Conduct Code Review') {
        input1.style.borderColor = '#28C76F';
        input1.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input1.textContent !== 'Conduct CI/CD Pipeline Review' || input1.textContent !== 'Conduct Code Review') {
        input1.style.borderColor = '#fd4445';
        input1.style.backgroundColor = '#fd4445';
      }

      //INPUT 2 & 3
      const input2 = document.getElementById('input2');
      const input3 = document.getElementById('input3');

      if (input2.textContent === 'Implement RBAC' || input2.textContent === 'POD Scanning') {
        input2.style.borderColor = '#28C76F';
        input2.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input3.textContent !== 'Implement RBAC' || input3.textContent !== 'POD Scanning') {
        input2.style.borderColor = '#fd4445';
        input2.style.backgroundColor = '#fd4445';
      }

      if (input3.textContent === 'Implement RBAC' || input3.textContent === 'POD Scanning') {
        input3.style.borderColor = '#28C76F';
        input3.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input3.textContent !== 'Implement RBAC' || input3.textContent !== 'POD Scanning') {
        input3.style.borderColor = '#fd4445';
        input3.style.backgroundColor = '#fd4445';
      }

      //INPUT 4 & 5
      const input4 = document.getElementById('input4');
      const input5 = document.getElementById('input5');

      if (input4.textContent === 'Implement URL Filtering' || input4.textContent === 'Implement a WAF') {
        input4.style.borderColor = '#28C76F';
        input4.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input5.textContent !== 'Implement URL Filtering' || input5.textContent !== 'Implement a WAF') {
        input4.style.borderColor = '#fd4445';
        input4.style.backgroundColor = '#fd4445';
      }

      if (input5.textContent === 'Implement URL Filtering' || input5.textContent === 'Implement a WAF') {
        input5.style.borderColor = '#28C76F';
        input5.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input5.textContent !== 'Implement URL Filtering' || input5.textContent !== 'Implement a WAF') {
        input5.style.borderColor = '#fd4445';
        input5.style.backgroundColor = '#fd4445';
      }

      //INPUT 6 & 7
      const input6 = document.getElementById('input6');
      const input7 = document.getElementById('input7');

      if (input6.textContent === 'Implement Input Validation' || input6.textContent === 'Drop Test DB') {
        input6.style.borderColor = '#28C76F';
        input6.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input7.textContent !== 'Implement Input Validation' || input7.textContent !== 'Drop Test DB') {
        input6.style.borderColor = '#fd4445';
        input6.style.backgroundColor = '#fd4445';
      }

      if (input7.textContent === 'Implement Input Validation' || input7.textContent === 'Drop Test DB') {
        input7.style.borderColor = '#28C76F';
        input7.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input7.textContent !== 'Implement Input Validation' || input7.textContent !== 'Drop Test DB') {
        input7.style.borderColor = '#fd4445';
        input7.style.backgroundColor = '#fd4445';
      }

      //INPUT 8 & 9
      const input8 = document.getElementById('input8');
      const input9 = document.getElementById('input9');

      if (input8.textContent === 'Implement row level access control' || input8.textContent === 'Verify OAuth Integrations') {
        input8.style.borderColor = '#28C76F';
        input8.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input9.textContent !== 'Implement row level access control' || input9.textContent !== 'Verify OAuth Integrations') {
        input8.style.borderColor = '#fd4445';
        input8.style.backgroundColor = '#fd4445';
      }

      if (input9.textContent === 'Implement row level access control' || input9.textContent === 'Verify OAuth Integrations') {
        input9.style.borderColor = '#28C76F';
        input9.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input9.textContent !== 'Implement row level access control' || input9.textContent !== 'Verify OAuth Integrations') {
        input9.style.borderColor = '#fd4445';
        input9.style.backgroundColor = '#fd4445';
      }

      //INPUT 10 & 11
      const input10 = document.getElementById('input10');
      const input11 = document.getElementById('input11');

      if (input10.textContent === 'Limit the size of user mailboxes' || input10.textContent === 'Implement anti-spam software') {
        input10.style.borderColor = '#28C76F';
        input10.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input11.textContent !== 'Limit the size of user mailboxes' || input11.textContent !== 'Implement anti-spam software') {
        input10.style.borderColor = '#fd4445';
        input10.style.backgroundColor = '#fd4445';
      }

      if (input11.textContent === 'Limit the size of user mailboxes' || input11.textContent === 'Implement anti-spam software') {
        input11.style.borderColor = '#28C76F';
        input11.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input11.textContent !== 'Limit the size of user mailboxes' || input11.textContent !== 'Implement anti-spam software') {
        input11.style.borderColor = '#fd4445';
        input11.style.backgroundColor = '#fd4445';
      }

      // Attack
      if (this.selectedAttackType && this.selectedAttackType.id === 2) {
        totalScore++;
        this.correctAttackType = true;
      } else {
        this.correctAttackType = false;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    draggable,
    vSelect,
    shuffle,
  },
  created() {
    this.attackTypeOptions = shuffle(this.attackTypeOptions);
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/views/platform/modules/simulations/legacy/css/Generic/Devices.scss';
.marvel-device.macbook {
  width: 43vw;
  max-width: 870px;
  height: 34rem;
}
.marvel-device.macbook .bottom-bar {
  top: 42rem;
}
.marvel-device.macbook .top-bar {
  top: 42rem;
}

.macbook .screen {
  background-image: url('https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335766951898906624/toronto-background.png');
  background-color: #002f65;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.simulation-grid {
  grid-template-columns: 2fr 1fr 2fr !important;
}
.network-switch-line-up {
  position: absolute;
  width: 6px;
  height: 500px;
  margin-bottom: 180px;
  background-color: #ffffff;
}
.hacker-pc-line-up {
  position: absolute;
  width: 6px;
  height: 650px;
  margin-bottom: 180px;
  background-color: #ffffff;
}
.app-connecting-line-across {
  position: absolute;
  width: 19vw;
  height: 4px;
  background-color: #ffffff;
}
.app-connecting-line-up {
  position: absolute;
  width: 6px;
  height: 70px;
  margin-bottom: 70px;
  background-color: #ffffff;
}
.ip-sec-vpn-tunnel {
  position: absolute;
  width: 14vw;
  height: 4px;
  background-color: #ffffff;
}
</style>

<style scoped>
#wordBank {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.6rem;
}
</style>
